import * as __button from '@syncfusion/ej2-buttons/button';
import * as _base from '@syncfusion/ej2-base';
import * as __checkbox from '@syncfusion/ej2-buttons/check-box';
import * as __contextmenu from '@syncfusion/ej2-navigations/context-menu';
import * as _lists from '@syncfusion/ej2-lists';
import * as _popups from '@syncfusion/ej2-popups';
import * as __datamanager from '@syncfusion/ej2-data/index';
import * as __datepicker from '@syncfusion/ej2-calendars/datepicker';
import * as _inputs from '@syncfusion/ej2-inputs';
import * as __calendar from '@syncfusion/ej2-calendars/calendar';
import * as __daterangepicker from '@syncfusion/ej2-calendars/daterangepicker';
import * as __datetimepicker from '@syncfusion/ej2-calendars/datetimepicker';
import * as __timepicker from '@syncfusion/ej2-calendars/timepicker';
import * as __dropdownbutton from '@syncfusion/ej2-splitbuttons/drop-down-button';
import * as __dropdownlist from '@syncfusion/ej2-dropdowns/drop-down-list';
import * as _data from '@syncfusion/ej2-data';
import * as __progressbutton from '@syncfusion/ej2-splitbuttons/progress-button';
import * as __tooltip from '@syncfusion/ej2-popups/tooltip';
import * as __uploader from '@syncfusion/ej2-inputs/uploader';
import * as __grid from '@syncfusion/ej2-grids/grid';
import * as __toolbar from '@syncfusion/ej2-navigations/toolbar';
import * as __autocomplete from '@syncfusion/ej2-dropdowns/auto-complete';
import * as _buttons from '@syncfusion/ej2-buttons';
import * as _excelexport from '@syncfusion/ej2-excel-export';
import * as _pdfexport from '@syncfusion/ej2-pdf-export';
import * as _fileutils from '@syncfusion/ej2-file-utils';
import * as _compression from '@syncfusion/ej2-compression';
import * as _splitbuttons from '@syncfusion/ej2-splitbuttons';
import * as __richtexteditor from '@syncfusion/ej2-richtexteditor/rich-text-editor';

function copy(copied, first, second, deep) {
    var result = copied || {};
    var length = arguments.length;
    if (deep) {
        length = length - 1;
    }
    var _loop_1 = function (i) {
        if (!arguments_1[i]) {
            return "continue";
        }
        var obj1 = arguments_1[i];
        Object.keys(obj1).forEach(function (key) {
            var src = result[key];
            var copy = obj1[key];
            var clone;
            if (deep && (isObject(copy) || Array.isArray(copy))) {
                if (isObject(copy)) {
                    clone = src ? src : {};
                    result[key] = copy({}, clone, copy, deep);
                }
                else {
                    clone = src ? src : [];
                    result[key] = copy([], clone, copy, deep);
                }
            }
            else {
                result[key] = copy;
            }
        });
    };
    var arguments_1 = arguments;
    for (var i = 1; i < length; i++) {
        _loop_1(i);
    }
    return result;
}

var buttons = {};
copy(buttons, __button);
copy(buttons, __checkbox);

var navigations = {};
copy(navigations, __contextmenu);

var popups = {};

var data = {};
copy(data, __datamanager);

var calendars = {};
copy(calendars, __datepicker);

var inputs = {};
copy(calendars, __calendar);
copy(calendars, __daterangepicker);
copy(calendars, __datetimepicker);
copy(calendars, __timepicker);

var splitbuttons = {};
copy(splitbuttons, __dropdownbutton);

var dropdowns = {};
copy(dropdowns, __dropdownlist);
copy(splitbuttons, __progressbutton);
copy(popups, __tooltip);
copy(inputs, __uploader);

__grid.Grid.Inject(__grid.Filter,__grid.Page,__grid.Selection,__grid.Sort,__grid.Group,__grid.Reorder,__grid.RowDD,__grid.DetailRow,__grid.Toolbar,__grid.Aggregate,__grid.Search,__grid.VirtualScroll,__grid.Edit,__grid.Resize,__grid.ExcelExport,__grid.PdfExport,__grid.CommandColumn,__grid.ContextMenu,__grid.Freeze,__grid.ColumnMenu,__grid.ColumnChooser,__grid.ForeignKey);

var grids = {};
copy(grids, __grid);
copy(navigations, __toolbar);
copy(dropdowns, __autocomplete);

__richtexteditor.RichTextEditor.Inject(__richtexteditor.Toolbar,__richtexteditor.Link,__richtexteditor.Image,__richtexteditor.Count,__richtexteditor.QuickToolbar,__richtexteditor.HtmlEditor,__richtexteditor.MarkdownEditor);

var richtexteditor = {};
copy(richtexteditor, __richtexteditor);

var base = _base;
var lists = _lists;
var data = _data;
var inputs = _inputs;
var popups = _popups;
var buttons = _buttons;
var excelexport = _excelexport;
var pdfexport = _pdfexport;
var fileutils = _fileutils;
var compression = _compression;
var splitbuttons = _splitbuttons;

export { buttons,base,navigations,lists,popups,data,calendars,inputs,splitbuttons,dropdowns,grids,excelexport,pdfexport,fileutils,compression,richtexteditor };